import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import React, { useState, useEffect } from 'react'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Calendar() {

    const [workoutLog, setWorkoutLog] = useState([
        { date: '2024-08-26', isCurrentMonth: false, isToday: false, isSelected: false,
            log: [
                {
                    name: 'Resistance',
                    complete: true
                },
                {
                    name: 'Cardio',
                    complete: true
                },
                {
                    name: 'Mobility',
                    complete: true
                },
                {
                    name: 'Skill/Sport',
                    complete: true
                }
            ]
        },
        { date: '2024-08-27', isCurrentMonth: false, isToday: false, isSelected: false,
            log: [
                {
                    name: 'Resistance',
                    complete: true
                },
                {
                    name: 'Cardio',
                    complete: true
                },
                {
                    name: 'Mobility',
                    complete: true
                },
                {
                    name: 'Skill/Sport',
                    complete: true
                }
            ]
        },
        { date: '2024-08-28', isCurrentMonth: false, isToday: false, isSelected: false,
            log: [
                {
                    name: 'Resistance',
                    complete: true
                },
                {
                    name: 'Cardio',
                    complete: true
                },
                {
                    name: 'Mobility',
                    complete: true
                },
                {
                    name: 'Skill/Sport',
                    complete: true
                }
            ]
        },
        { date: '2024-08-29', isCurrentMonth: false, isToday: false, isSelected: false,
            log: [
                {
                    name: 'Resistance',
                    complete: true
                },
                {
                    name: 'Cardio',
                    complete: true
                },
                {
                    name: 'Mobility',
                    complete: true
                },
                {
                    name: 'Skill/Sport',
                    complete: true
                }
            ]
        },
        { date: '2024-08-30', isCurrentMonth: false, isToday: false, isSelected: false,
            log: [
                {
                    name: 'Resistance',
                    complete: true
                },
                {
                    name: 'Cardio',
                    complete: true
                },
                {
                    name: 'Mobility',
                    complete: true
                },
                {
                    name: 'Skill/Sport',
                    complete: true
                }
            ]
        },
        { date: '2024-08-31', isCurrentMonth: false, isToday: false, isSelected: false,
            log: [
                {
                    name: 'Resistance',
                    complete: true
                },
                {
                    name: 'Cardio',
                    complete: true
                },
                {
                    name: 'Mobility',
                    complete: true
                },
                {
                    name: 'Skill/Sport',
                    complete: true
                }
            ]
        },
        { date: '2024-09-01', isCurrentMonth: true, isToday: false, isSelected: false,
          log: [
              {
                  name: 'Resistance',
                  complete: true
              },
              {
                  name: 'Cardio',
                  complete: true
              },
              {
                  name: 'Mobility',
                  complete: true
              },
              {
                  name: 'Skill/Sport',
                  complete: true
              }
          ]
        },
        { date: '2024-09-02', isCurrentMonth: true, isToday: false, isSelected: false,
          log: [
              {
                  name: 'Resistance',
                  complete: false
              },
              {
                  name: 'Cardio',
                  complete: false
              },
              {
                  name: 'Mobility',
                  complete: false
              },
              {
                  name: 'Skill/Sport',
                  complete: false
              }
          ]
        },
        { date: '2024-09-03', isCurrentMonth: true,  isToday: false, isSelected: false,
          log: [
              {
                  name: 'Resistance',
                  complete: true
              },
              {
                  name: 'Cardio',
                  complete: true
              },
              {
                  name: 'Mobility',
                  complete: false
              },
              {
                  name: 'Skill/Sport',
                  complete: false
              }
          ]
        },
        { date: '2024-09-04', isCurrentMonth: true,  isToday: false, isSelected: false,
          log: [
              {
                  name: 'Resistance',
                  complete: true
              },
              {
                  name: 'Cardio',
                  complete: true
              },
              {
                  name: 'Mobility',
                  complete: true
              },
              {
                  name: 'Skill/Sport',
                  complete: false
              }
          ]
        },
        { date: '2024-09-05', isCurrentMonth: true,  isToday: false, isSelected: false,
          log: [
              {
                  name: 'Resistance',
                  complete: true
              },
              {
                  name: 'Cardio',
                  complete: true
              },
              {
                  name: 'Mobility',
                  complete: true
              },
              {
                  name: 'Skill/Sport',
                  complete: true
              }
          ]
        },
        { date: '2024-09-06', isCurrentMonth: true,  isToday: false, isSelected: false,
          log: [
              {
                  name: 'Resistance',
                  complete: true
              },
              {
                  name: 'Cardio',
                  complete: true
              },
              {
                  name: 'Mobility',
                  complete: true
              },
              {
                  name: 'Skill/Sport',
                  complete: true
              }
          ]
        },
        { date: '2024-09-07', isCurrentMonth: true,  isToday: false, isSelected: false,
          log: [
              {
                  name: 'Resistance',
                  complete: true
              },
              {
                  name: 'Cardio',
                  complete: true
              },
              {
                  name: 'Mobility',
                  complete: true
              },
              {
                  name: 'Skill/Sport',
                  complete: true
              }
          ]
        },
        { date: '2024-09-08', isCurrentMonth: true,  isToday: false, isSelected: false,
          log: [
              {
                  name: 'Resistance',
                  complete: true
              },
              {
                  name: 'Cardio',
                  complete: true
              },
              {
                  name: 'Mobility',
                  complete: true
              },
              {
                  name: 'Skill/Sport',
                  complete: true
              }
          ]
        },
        { date: '2024-09-09', isCurrentMonth: true,  isToday: false, isSelected: false,
          log: [
              {
                  name: 'Resistance',
                  complete: true
              },
              {
                  name: 'Cardio',
                  complete: true
              },
              {
                  name: 'Mobility',
                  complete: true
              },
              {
                  name: 'Skill/Sport',
                  complete: true
              }
          ]
        },
        { date: '2024-09-10', isCurrentMonth: true,  isToday: false, isSelected: false,
          log: [
              {
                  name: 'Resistance',
                  complete: true
              },
              {
                  name: 'Cardio',
                  complete: true
              },
              {
                  name: 'Mobility',
                  complete: true
              },
              {
                  name: 'Skill/Sport',
                  complete: true
              }
          ]
        },
        { date: '2024-09-11', isCurrentMonth: true,  isToday: false, isSelected: false,
          log: [
              {
                  name: 'Resistance',
                  complete: true
              },
              {
                  name: 'Cardio',
                  complete: true
              },
              {
                  name: 'Mobility',
                  complete: true
              },
              {
                  name: 'Skill/Sport',
                  complete: true
              }
          ]
        },
        { date: '2024-09-12', isCurrentMonth: true,  isToday: false, isSelected: false,
          log: [
              {
                  name: 'Resistance',
                  complete: true
              },
              {
                  name: 'Cardio',
                  complete: true
              },
              {
                  name: 'Mobility',
                  complete: true
              },
              {
                  name: 'Skill/Sport',
                  complete: true
              }
          ]
        },
        { date: '2024-09-13', isCurrentMonth: true,  isToday: false, isSelected: false,
          log: [
              {
                  name: 'Resistance',
                  complete: true
              },
              {
                  name: 'Cardio',
                  complete: true
              },
              {
                  name: 'Mobility',
                  complete: true
              },
              {
                  name: 'Skill/Sport',
                  complete: true
              }
          ]
        },
        { date: '2024-09-14', isCurrentMonth: true,  isToday: false, isSelected: false,
          log: [
              {
                  name: 'Resistance',
                  complete: true
              },
              {
                  name: 'Cardio',
                  complete: true
              },
              {
                  name: 'Mobility',
                  complete: true
              },
              {
                  name: 'Skill/Sport',
                  complete: true
              }
          ]
        },
        { date: '2024-09-15', isCurrentMonth: true,  isToday: false, isSelected: false,
          log: [
              {
                  name: 'Resistance',
                  complete: true
              },
              {
                  name: 'Cardio',
                  complete: true
              },
              {
                  name: 'Mobility',
                  complete: true
              },
              {
                  name: 'Skill/Sport',
                  complete: true
              }
          ]
        },
        { date: '2024-09-16', isCurrentMonth: true,  isToday: false, isSelected: false,
          log: [
              {
                  name: 'Resistance',
                  complete: true
              },
              {
                  name: 'Cardio',
                  complete: true
              },
              {
                  name: 'Mobility',
                  complete: true
              },
              {
                  name: 'Skill/Sport',
                  complete: true
              }
          ]
        },
        { date: '2024-09-17', isCurrentMonth: true,  isToday: false, isSelected: false,
          log: [
              {
                  name: 'Resistance',
                  complete: true
              },
              {
                  name: 'Cardio',
                  complete: true
              },
              {
                  name: 'Mobility',
                  complete: true
              },
              {
                  name: 'Skill/Sport',
                  complete: true
              }
          ]
        },
        { date: '2024-09-18', isCurrentMonth: true,  isToday: false, isSelected: false,
          log: [
              {
                  name: 'Resistance',
                  complete: true
              },
              {
                  name: 'Cardio',
                  complete: true
              },
              {
                  name: 'Mobility',
                  complete: true
              },
              {
                  name: 'Skill/Sport',
                  complete: true
              }
          ]
        },
        { date: '2024-09-19', isCurrentMonth: true,  isToday: false, isSelected: false,
          log: [
              {
                  name: 'Resistance',
                  complete: true
              },
              {
                  name: 'Cardio',
                  complete: true
              },
              {
                  name: 'Mobility',
                  complete: true
              },
              {
                  name: 'Skill/Sport',
                  complete: true
              }
          ]
        },
        { date: '2024-09-20', isCurrentMonth: true,  isToday: false, isSelected: false,
          log: [
              {
                  name: 'Resistance',
                  complete: true
              },
              {
                  name: 'Cardio',
                  complete: true
              },
              {
                  name: 'Mobility',
                  complete: true
              },
              {
                  name: 'Skill/Sport',
                  complete: true
              }
          ]
        },
        { date: '2024-09-21', isCurrentMonth: true,  isToday: false, isSelected: false,
          log: [
              {
                  name: 'Resistance',
                  complete: true
              },
              {
                  name: 'Cardio',
                  complete: true
              },
              {
                  name: 'Mobility',
                  complete: true
              },
              {
                  name: 'Skill/Sport',
                  complete: true
              }
          ]
        },
        { date: '2024-09-22', isCurrentMonth: true,  isToday: false, isSelected: true,
          log: [
              {
                  name: 'Resistance',
                  complete: true
              },
              {
                  name: 'Cardio',
                  complete: true
              },
              {
                  name: 'Mobility',
                  complete: true
              },
              {
                  name: 'Skill/Sport',
                  complete: true
              }
          ]
        },
        { date: '2024-09-23', isCurrentMonth: true,  isToday: false, isSelected: false,
          log: [
              {
                  name: 'Resistance',
                  complete: true
              },
              {
                  name: 'Cardio',
                  complete: true
              },
              {
                  name: 'Mobility',
                  complete: true
              },
              {
                  name: 'Skill/Sport',
                  complete: true
              }
          ]
        },
        { date: '2024-09-24', isCurrentMonth: true,  isToday: false, isSelected: false,
          log: [
              {
                  name: 'Resistance',
                  complete: true
              },
              {
                  name: 'Cardio',
                  complete: true
              },
              {
                  name: 'Mobility',
                  complete: true
              },
              {
                  name: 'Skill/Sport',
                  complete: true
              }
          ]
        },
        { date: '2024-09-25', isCurrentMonth: true,  isToday: false, isSelected: false,
          log: [
              {
                  name: 'Resistance',
                  complete: true
              },
              {
                  name: 'Cardio',
                  complete: true
              },
              {
                  name: 'Mobility',
                  complete: true
              },
              {
                  name: 'Skill/Sport',
                  complete: true
              }
          ]
        },
        { date: '2024-09-26', isCurrentMonth: true,  isToday: false, isSelected: false,
          log: [
              {
                  name: 'Resistance',
                  complete: true
              },
              {
                  name: 'Cardio',
                  complete: true
              },
              {
                  name: 'Mobility',
                  complete: true
              },
              {
                  name: 'Skill/Sport',
                  complete: true
              }
          ]
        },
        { date: '2024-09-27', isCurrentMonth: true,  isToday: false, isSelected: false,
          log: [
              {
                  name: 'Resistance',
                  complete: true
              },
              {
                  name: 'Cardio',
                  complete: true
              },
              {
                  name: 'Mobility',
                  complete: true
              },
              {
                  name: 'Skill/Sport',
                  complete: true
              }
          ]
        },
        { date: '2024-09-28', isCurrentMonth: true,  isToday: false, isSelected: false,
          log: [
              {
                  name: 'Resistance',
                  complete: true
              },
              {
                  name: 'Cardio',
                  complete: true
              },
              {
                  name: 'Mobility',
                  complete: true
              },
              {
                  name: 'Skill/Sport',
                  complete: true
              }
          ]
        },
        { date: '2024-09-29', isCurrentMonth: true,  isToday: false, isSelected: false,
          log: [
              {
                  name: 'Resistance',
                  complete: true
              },
              {
                  name: 'Cardio',
                  complete: true
              },
              {
                  name: 'Mobility',
                  complete: true
              },
              {
                  name: 'Skill/Sport',
                  complete: true
              }
          ]
        },
        { date: '2024-09-30', isCurrentMonth: true,  isToday: false, isSelected: false,
          log: [
              {
                  name: 'Resistance',
                  complete: true
              },
              {
                  name: 'Cardio',
                  complete: true
              },
              {
                  name: 'Mobility',
                  complete: true
              },
              {
                  name: 'Skill/Sport',
                  complete: true
              }
          ]
        },
        { date: '2024-10-01', isCurrentMonth: false,  isToday: false, isSelected: false,
            log: [
                {
                    name: 'Resistance',
                    complete: true
                },
                {
                    name: 'Cardio',
                    complete: true
                },
                {
                    name: 'Mobility',
                    complete: true
                },
                {
                    name: 'Skill/Sport',
                    complete: true
                }
            ]
        },
        { date: '2024-10-02', isCurrentMonth: false,  isToday: false, isSelected: false,
            log: [
                {
                    name: 'Resistance',
                    complete: true
                },
                {
                    name: 'Cardio',
                    complete: true
                },
                {
                    name: 'Mobility',
                    complete: true
                },
                {
                    name: 'Skill/Sport',
                    complete: true
                }
            ]
        },
        { date: '2024-10-03', isCurrentMonth: false,  isToday: false, isSelected: false,
            log: [
                {
                    name: 'Resistance',
                    complete: true
                },
                {
                    name: 'Cardio',
                    complete: true
                },
                {
                    name: 'Mobility',
                    complete: true
                },
                {
                    name: 'Skill/Sport',
                    complete: true
                }
            ]
        },
        { date: '2024-10-04', isCurrentMonth: false,  isToday: false, isSelected: false,
            log: [
                {
                    name: 'Resistance',
                    complete: true
                },
                {
                    name: 'Cardio',
                    complete: true
                },
                {
                    name: 'Mobility',
                    complete: true
                },
                {
                    name: 'Skill/Sport',
                    complete: true
                }
            ]
        },
        { date: '2024-10-05', isCurrentMonth: false,  isToday: false, isSelected: false,
            log: [
                {
                    name: 'Resistance',
                    complete: true
                },
                {
                    name: 'Cardio',
                    complete: true
                },
                {
                    name: 'Mobility',
                    complete: true
                },
                {
                    name: 'Skill/Sport',
                    complete: true
                }
            ]
        },
        { date: '2024-10-06', isCurrentMonth: false,  isToday: false, isSelected: false,
            log: [
                {
                    name: 'Resistance',
                    complete: true
                },
                {
                    name: 'Cardio',
                    complete: true
                },
                {
                    name: 'Mobility',
                    complete: true
                },
                {
                    name: 'Skill/Sport',
                    complete: true
                }
            ]
        },
      ])

    const findSelectedLog = () =>
    {
        const selectedItem = workoutLog.find(item => item.isSelected);
        return selectedItem ? selectedItem.log : null;
    };

    const findSelectedMonth = () =>
    {
        const selectedItem = workoutLog.find(item => item.isCurrentMonth);
            // console.log(selectedItem.date.getMonth())
            //return selectedItem ? selectedItem.date.toLocaleDateString('en-US', { month: 'long' }) : null;
            // return "Yo"
            return new Date().toLocaleDateString('en-US', { month: 'long' })
            // return selectedItem.date.toLocaleDateString('en-US', { month: 'long' })
    };

    const countLogsDone = (data) =>
    {
        return data.log.filter(entry => entry.complete).length;
    }

    const selectedLog = findSelectedLog();
    const selectedMonth = findSelectedMonth();
    
  return (
    <div>
      <div className="flex items-center">
        <h2 className="flex-auto text-3xl font-semibold text-gray-900">{selectedMonth} 2024</h2>
        <button
          type="button"
          className="-my-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
        >
          <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
        </button>
        <button
          type="button"
          className="-my-1.5 -mr-1.5 ml-2 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
        >
          <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
      <div className="mt-10 grid grid-cols-7 text-center text-xs leading-6 text-gray-500">
        <div>M</div>
        <div>T</div>
        <div>W</div>
        <div>T</div>
        <div>F</div>
        <div>S</div>
        <div>S</div>
      </div>
      <div className="mt-2 grid grid-cols-7 text-sm">
        {workoutLog.map((date, dayIdx) => (
          <div key={date.date} className={classNames(dayIdx > 6 && 'border-t border-gray-200', 'py-2')}>
            <button
              type="button"
              className={classNames(
                // date.isSelected && 'text-white',
                date.isCurrentMonth && countLogsDone(date) === 0 && 'text-red-500',
                date.isCurrentMonth && countLogsDone(date) > 1 && countLogsDone(date) < 4 && 'text-yellow-500',
                date.isCurrentMonth && countLogsDone(date) === 4 && 'text-green-500',
                // !date.isSelected && date.isCurrentMonth && 'text-gray-900',
                !date.isCurrentMonth && 'text-gray-400',
                date.isSelected && 'bg-gray-900',
                // !date.isSelected && 'hover:bg-gray-200',
                date.isSelected && 'font-semibold',
                'mx-auto flex h-8 w-8 items-center justify-center rounded-full',
              )}
            >
              <time dateTime={date.date}>{date.date.split('-').pop().replace(/^0/, '')}</time>
            </button>
          </div>
        ))}
      </div>
      <section className="mt-12">
        <h2 className="text-base font-semibold leading-6 text-gray-900">
          Training Log for <time dateTime="2022-01-21">September 22, 2024</time>
        </h2>
            {selectedLog && (
                <ul>
                    {selectedLog.map((item,index) => (
                        <li key={index}>
                            <div className="h-6">
                                <input id="comments" aria-describedby="comments-description" name="comments" type="checkbox" className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"></input>
                                <label className="font-medium text-black">{item.name}</label>
                            </div>
                        </li>
                    ))}
                </ul>
            )}
            
                        
      </section>
    </div>
  )
}
